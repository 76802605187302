
import * as customProviderRuntime$RJDQgx8lFB from '/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.6.16_@formkit+core@1.6.5_@formkit+inputs@1.6.5_nuxt@3.11.2_rollup@4.18._2muatbiw6zxtgfub66wmwwv2b4/node_modules/@smatched/nuxt-core/provider/layerProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "layerProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['layerProvider']: { provider: customProviderRuntime$RJDQgx8lFB, defaults: undefined }
}
        